<template>
  <div :class="template.isActiveProject === true ? 'shortlist-project mb-3 green-border' : 'shortlist-project mb-3'">
    <GreenCheckIcon v-if="template.isActiveProject === true" />
    <div class="content-text">
      <div class="content-text__title pb-2">
        <div class="content-text__title-group pb-1">
          <ProjectIcon />
          <div class="title">{{ $t('project-title') }}</div>
        </div>
        <div class="text">{{ $t('project-title-text') }}</div>
      </div>
    </div>
    <div class="content-inputs">
      <div v-if="template.isActiveProject === false" class="content-inputs__row" :key="keyValue">
        <AppSelect
            v-model="template.project_name"
            :options="projects ? projects : []"
            field="name"
            :placeholder="$t('select-project')"
            @input="addFromProject()"
        />
        <button @click="openCreateProjectForm" class="btn btn-block btn-primary-light">{{ $t('create') }}</button>
      </div>
      <div v-else class="direction-row content-inputs__row">
        <span class="project-name">{{ template.project_name.name }}</span>
        <button class="btn btn-link cancel-select" @click="cancelSelectProject">
          <StepBackIcon />
        </button>
      </div>
    </div>
    <div v-if="isCreateProjectFormIsOpen === true" class="content-inputs__form">
      <div class="form-container">
        <div class="form-container__in">
          <h4>{{ $t('title-add-project') }}</h4>
          <ProjectTemplate class="pt-2" :project="project" :errors="errors" key="new-project"/>
        </div>
      </div>
      <div class="form-btn mt-4">
        <button @click="createProject" type="button" class="btn btn-block btn-primary-light btn-modal mt-4" >
          {{ $t("save") }}
        </button>
        <button @click="cancelCreateProject" type="button" class="btn btn-block btn-primary btn-modal" >
          {{ $t("cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProjectIcon from '@/components/icons/sending-an-assignment/ProjectIcon';
import AppSelect from '@/components/forms/AppSelect';
import ProjectTemplate from '@/components/profile-company/ProjectTemplate';
import Notifications from '../../../services/notifications.service';
import GreenCheckIcon from '@/components/icons/sending-an-assignment/GreenCheckIcon';
import StepBackIcon from '@/components/icons/sending-an-assignment/StepBackIcon';
import Errors from '../../../utils/errors';

export default {
  name: 'shortlist-content-project',
  i18nOptions: { namespaces: 'shortlist-content-project' },
  components: {
    ProjectIcon,
    AppSelect,
    ProjectTemplate,
    GreenCheckIcon,
    StepBackIcon,
  },
  data() {
    return {
      keyValue: 1,
      errors: new Errors(),
    };
  },
  computed: {
    ...mapState({
      projects: state => state.projects.projects,
      template: state => state.templates.template,
      project: state => state.projects.project,
      isCreateProjectFormIsOpen: state => state.templates.isCreatedProjectFormIsOpen,
    }),
  },
  mounted() {
    this.$store.dispatch('projects/getProjects');
  },
  methods: {
    addFromProject() {
      this.cancelCreateProject();
      this.setProjectState();
      this.$store.dispatch('roles/getProjectRoles', this.template.project_uuid);
      this.$store.commit('projects/activeProjectId', this.template.project_uuid);
      this.$store.commit('roles/roleMinDate', this.template.project_name.start_date);
      this.$store.commit('roles/roleMaxDate', this.template.project_name.end_date);
    },
    openCreateProjectForm() {
      this.$store.commit('templates/isCreatedProjectFormIsOpen', true);
    },
    createProject() {
      this.loading = true;

      this.$store
        .dispatch('projects/createProject')
        .then(() => {
          this.errors.clear();
          Notifications.notify('success', 'Project added');
          this.$store.commit('roles/roleMinDate', this.project.start_date);
          this.$store.commit('roles/roleMaxDate', this.project.end_date);
          this.$store.dispatch('projects/getProjects');
          this.cancelCreateProject();
          this.setProjectState();
          this.$store.dispatch('roles/getProjectRoles', this.template.project_uuid);
          this.keyValue += 1;
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            this.errors.set(err.response.data.errors);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setProjectState() {
      const payload = {
        project_uuid: this.template.project_name.uuid,
        isActiveProject: true,
      };
      this.$store.commit('templates/templateProps', payload);
    },
    cancelCreateProject() {
      this.$store.commit('projects/resetProject');
      this.$store.commit('templates/isCreatedProjectFormIsOpen', false);
      this.$store.commit('templates/isCreatedRoleFormIsOpen', false);
    },
    cancelSelectProject() {
      const payload = {
        role_name: '',
        project_role_uuid: '',
        project_name: '',
        desired_availability: [],
        isActiveRole: false,
        isActiveProject: false,
      };
      this.$store.commit('templates/templateProps', payload);
    },
  },
};
</script>
