<template>
  <div class="shortlist-project mb-3">
    <div v-if="isActive === true">
      <div class="content-text">
        <div class="content-text__title pb-2">
          <div class="content-text__title-group pb-1">
            <SendIcon />
            <div class="title">{{ $t('save-title') }}</div>
          </div>
          <div class="text">{{ $t('save-title-text') }}</div>
        </div>
        <div class="content-inputs__row">
          <AppSelect
              v-if="template.neoworker_uuid"
              v-model="selectedTemplate"
              :options="templates ? templates : []"
              field="name"
              :placeholder="$t('select-template')"
              :allowEmpty="true"
              :disabled="isCreateShortListFormIsOpen ? true : false"/>
          <button v-if="!selectedTemplate" @click="openShortListForm" class="btn btn-block btn-primary-light">{{ $t('create') }}</button>
        </div>
      </div>
      <div v-if="selectedTemplate" class="content-inputs">
        <div class="content-inputs__row">
          <button @click="addWorkerToShortlist" class="btn btn-block btn-primary-light">{{ $t('save') }}</button>
        </div>
      </div>
      <div v-if="isCreateShortListFormIsOpen === true" class="content-inputs__form">
        <div class="send-assignment-content">
          <ShortListTemplate :template="template" />
          <div class="form-btn mt-4">
            <button @click="saveTemplate" type="button" class="btn btn-block btn-primary-light btn-modal" >
              {{ $t("save") }}
            </button>
            <button @click="cancel" type="button" class="btn btn-block btn-primary btn-modal" >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="content-text inactive">
        <div class="content-text__title pb-2">
          <div class="content-text__title-group pb-1">
            <div class="title">{{ $t('save-title') }}</div>
          </div>
          <div class="text">{{ $t('save-title-text') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SendIcon from '@/components/icons/sending-an-assignment/SendIcon';
import ShortListTemplate from '@/components/company/save-to-shortlist/ShortListTemplate';
import AppSelect from '@/components/forms/AppSelect';
import Errors from '@/utils/errors';
import Notifications from '@/services/notifications.service';

export default {
  name: 'shortlist',
  i18nOptions: { namespaces: 'shortlist-content-save' },
  components: {
    SendIcon,
    AppSelect,
    ShortListTemplate,
  },
  data() {
    return {
      selectedTemplate: null,
      loading: false,
      errors: new Errors(),
    };
  },
  props: ['isActive'],
  computed: {
    ...mapState({
      template: state => state.templates.template,
      templates: state => state.templates.templates,
      isCreateShortListFormIsOpen: state => state.templates.isCreateShortListFormIsOpen,
      searchForm: state => state.search.searchForm,
      advancedForm: state => state.search.advancedForm,
      shortlistData: state => state.shortlist.shortlistData,
    }),
  },
  methods: {
    openShortListForm() {
      this.$store.commit('templates/isCreateShortListFormIsOpen', true);
    },
    saveTemplate() {
      const searchData = {
        searchForm: this.searchForm,
        advancedForm: this.advancedForm,
      };
      this.loading = true;
      this.$store.dispatch('templates/saveTemplate', searchData)
        .then((res) => {
          this.selectedTemplate = res.data;
          this.$store.dispatch('templates/getTemplates');
          if (this.template.neoworker_uuid) {
            this.addWorkerToShortlist();
          } else {
            this.$store.commit('shortlist/shortlistDataProps', { search_template_id: this.selectedTemplate.id });
            this.success(res);
          }
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            Notifications.notify('danger', err.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$store.dispatch('shortlist/resetShortList');
      this.$store.commit('templates/resetTemplate');
      this.$store.commit('templates/isCreateShortListFormIsOpen', false);
      this.$router.push('/search');
    },
    addWorkerToShortlist() {
      this.$store.commit('shortlist/shortlistDataProps', { search_template_id: this.selectedTemplate.id });
      this.$store.dispatch('shortlist/addWorkerToShortlist')
        .then((res) => {
          this.success(res);
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            Notifications.notify('danger', err.data.message);
          }
        });
    },
    success(res) {
      this.$store.dispatch('shortlist/getShortlistByTemplate', this.shortlistData.search_template_id);
      this.$store.commit('shortlist/shortlistDataProps', { neoworker_uuid: null });
      this.$store.commit('templates/resetTemplate');
      this.$store.commit('templates/isCreateShortListFormIsOpen', false);
      setTimeout(() => {
        this.$store.commit('templates/activeTemplate', this.selectedTemplate);
      }, 400);
      this.$router.push('/search');
      if (typeof res.data.message !== 'undefined') {
        Notifications.notify('success', res.data.message);
      } else {
        Notifications.notify('success', this.$t('created'));
      }
    },
  },
};
</script>
