<template>
  <div class="form-container">
    <div class="form-container__in">
      <h4>{{ $t('title') }}</h4>
      <div class="row">
        <div class="col-md-12">
          <AppInput
              v-model="template.name"
              :label="$t('template_name')"
              type="text"
              field="days"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from '@/components/forms/AppInput';

export default {
  name: 'shortlist-template',
  i18nOptions: { namespaces: 'shortlist-save-form' },
  components: {
    AppInput,
  },
  props: ['template', 'errors'],
  data() {
    return {
      loading: false,
    };
  },
};
</script>
