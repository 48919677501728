<template>
  <div class="dashboard-page direction-column">
    <div class="save-to-shortlist">
      <div class="save-to-shortlist__content">
        <div class="save-to-shortlist__heading pb-3">
          <h3>{{ $t("explanation") }}</h3>
          <div class="explanation-content">{{ $t('explanation-content') }}</div>
        </div>
        <div class="save-to-shortlist__body pb-1">
          <ShortlistProject />
          <ShortlistRole :isActive="template.isActiveProject" />
          <ShortList :isActive="template.isActiveRole" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ShortlistProject from '@/components/company/save-to-shortlist/ShortlistProject';
import ShortlistRole from '@/components/company/save-to-shortlist/ShortlistRole';
import ShortList from '@/components/company/save-to-shortlist/ShortList';

export default {
  name: 'save-to-shortlist',
  i18nOptions: { namespaces: 'save-to-shortlist' },
  components: {
    ShortlistProject,
    ShortlistRole,
    ShortList,
  },
  computed: {
    ...mapState({
      template: state => state.templates.template,
    }),
  },
};
</script>
